export const APP_HEADER_TITLE = 'EVENTS_MA_PAGE.APP_HEADER_TITLE'
export const EVENT_SUMMARY = 'EVENTS_MA_PAGE.EVENT_SUMMARY'
export const NO_EVENTS = 'EVENTS_MA_PAGE.NO_EVENTS'

export const ORDER_DETAILS = 'EVENTS_MA_PAGE.ORDER_DETAILS'

export const EXPANDABLE_ROW = 'EVENTS_MA_PAGE.EXPANDABLE_ROW'
export const EXPANDABLE_ROW_ICON = 'EVENTS_MA_PAGE.EXPANDABLE_ROW_ICON'

export const BUTTON_GO_TO_DETAILS = 'EVENTS_MA_PAGE.BUTTON_GO_TO_DETAILS'
export const BUTTON_CANCEL_RSVP = 'EVENTS_MA_PAGE.BUTTON_CANCEL_RSVP'
export const BUTTON_SHARE = 'EVENTS_MA_PAGE.BUTTON_SHARE'
export const BUTTON_SHOW_ORDERS = 'EVENTS_MA_PAGE.BUTTON_SHOW_ORDERS'
export const BUTTON_DOWNLOAD_TICKETS = 'EVENTS_MA_PAGE.BUTTON_DOWNLOAD_TICKETS'
export const BUTTON_GO_TO_EVENTS = 'EVENTS_MA_PAGE.BUTTON_GO_TO_EVENTS'
