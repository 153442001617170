import {withExperiments, ExperimentsProvider} from '@wix/wix-experiments-react'
import * as classNames from 'classnames'
import * as React from 'react'
import {I18nextProvider} from 'react-i18next'
import {TPAComponentsProvider} from 'wix-ui-tpa/dist/src/components/TPAComponentsConfig'
import {Resizable} from '../../commons/components/hoc/resizable'
import {RuntimeContext} from '../../commons/components/runtime-context/runtime-context'
import {Direction} from '../../commons/constants/html'
import {isMobile} from '../../commons/selectors/environment'
import i18n from '../../commons/services/i18n'
import {ROOT_COMPONENT_ID} from '../config/constants'
import {WidgetProps} from '../interface'
import {shouldLoadMore} from '../selectors/events'
import {resizeHeight} from '../services/resize'
import {ErrorBoundary} from './error-boundary'
import {Router} from './router'
import s from './widget.scss'

const SCROLL_CUTOFF = 0.95

export default class WidgetRoot extends React.Component<WidgetProps> {
  componentDidMount(): void {
    this.props.registerToScroll(({progressY}) => {
      if (progressY > SCROLL_CUTOFF && shouldLoadMore(this.props.state)) {
        this.props.actions.getEvents()
      }
    })
  }

  resize = () => {
    resizeHeight(this.props.state)
  }

  render() {
    const {
      environment: {language},
      experiments
    } = this.props.state

    return (
      <ErrorBoundary>
        <RuntimeContext.Provider value={this.props}>
          <TPAComponentsProvider value={{mobile: isMobile(this.props.state)}}>
            <I18nextProvider i18n={i18n(language, 'members-page')}>
              <ExperimentsProvider options={{experiments}}>
                <Widget
                  resize={this.resize}
                  mobile={isMobile(this.props.state)}
                  isRTL={this.props.isRTL}
                  route={this.props.state.navigation.route}
                />
              </ExperimentsProvider>
            </I18nextProvider>
          </TPAComponentsProvider>
        </RuntimeContext.Provider>
      </ErrorBoundary>
    )
  }
}

const WidgetPresentation = ({resize, mobile, route, isRTL}) => (
  <div dir={isRTL ? Direction.RTL : Direction.LTR}>
    <div id={ROOT_COMPONENT_ID} className={classNames(s.root, {[s.mobile]: mobile})}>
      <Resizable resizeFunc={resize}>
        <Router route={route} mobile={mobile} />
      </Resizable>
    </div>
  </div>
)

export const Widget = withExperiments<any>(WidgetPresentation)
